import { memo } from 'react';

import { StyleProps } from './general';

type BackButtonProps = { light?: boolean; title?: string; onClick: () => void } & StyleProps;

const Back: React.FC<BackButtonProps> = ({ light, title, onClick, className }) => {
  return (
    <div className={`flex items-center p-2.5 cursor-pointer ${className}`} onClick={onClick}>
      <img src={light ? '/images/back-arrow-white.png' : '/images/back-arrow-black.png'} alt="back" className="h-5" />
      <p className="pl-2.5 text-base text-gray-600">{title}</p>
    </div>
  );
};

export const BackButton = memo(Back);

type DismissButtonProps = { light?: boolean; onClick: () => void } & StyleProps;

const Dismiss: React.FC<DismissButtonProps> = ({ light, onClick, className }) => {
  return (
    <div className={`flex items-center p-2.5 cursor-pointer ${className}`} onClick={onClick}>
      <img src={light ? '/images/x-white.png' : '/images/x-black.png'} alt="back" className="h-4" />
    </div>
  );
};

export const DismissButton = memo(Dismiss);

type TextButtonProps = { disabled?: boolean; loading?: boolean; title: string; onClick: () => void } & StyleProps;

const Text: React.FC<TextButtonProps> = ({ disabled, loading, title, className, onClick }) => {
  return (
    <button
      className={`text-sm focus:outline-none cursor-pointer font-medium ${className}`}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export const TextButton = memo(Text);

type MainButtonProps = {
  color?: string;
  disabled?: boolean;
  loading?: boolean;
  title: string;
  onClick: () => void;
} & StyleProps;

const Main: React.FC<MainButtonProps> = ({ color, disabled, loading, title, className, onClick }) => {
  return (
    <button
      className={`${
        color ? color : 'bg-pink-300'
      } disabled:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:ring-opacity-50 rounded-full h-14 lg:h-12 text-white font-medium text-sm uppercase tracking-wide transition duration-300 ease-in-out ${className}`}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export const MainButton = memo(Main);

type SplashButtonProps = {
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
};

const Splash: React.FC<SplashButtonProps> = ({ disabled, loading, onClick }) => {
  return (
    <button
      className="w-full lg:w-1/5 lg:mx-auto bg-white disabled:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:ring-opacity-50 rounded-full h-14 lg:h-12 disabled:text-white text-gray-900 font-medium text-sm uppercase tracking-wide transition duration-300 ease-in-out"
      disabled={disabled || loading}
      onClick={onClick}
    >
      Let&apos;s Go
    </button>
  );
};

export const SplashButton = memo(Splash);

type CountButtonProps = MainButtonProps & { count: number };

const Count: React.FC<CountButtonProps> = ({ count, disabled, loading, title, className, onClick }) => {
  return (
    <button
      className={`bg-pink-300 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:ring-opacity-50 rounded-full h-14 text-white font-medium text-sm uppercase tracking-wide transition duration-300 ease-in-out ${className}`}
      disabled={disabled || loading}
      onClick={onClick}
    >
      <p className="absolute left-10">{count}</p>
      {title}
    </button>
  );
};

export const CountButton = memo(Count);
