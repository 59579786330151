import Div100vh from 'react-div-100vh';

// Default page wrapper.
type PageProps = { className?: string };

const Page: React.FC<PageProps> = ({ children, className }) => {
  return (
    <Div100vh>
      <div data-label="page" className={`min-h-full w-full flex flex-col ${className}`}>
        {children}
      </div>
    </Div100vh>
  );
};

export default Page;

type ContentProps = PageProps & { desktop?: boolean };

export const Content: React.FC<ContentProps> = ({ children, className, desktop = false }) => {
  return (
    <div
      data-label="content"
      className={`w-full flex-col lg:max-w-5xl mx-auto ${className} ${desktop ? 'hidden lg:flex' : 'flex'}`}
    >
      {children}
    </div>
  );
};
